import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => {
  return {
    currentCurrency: {},
    currentCurrencyLeverage: {},
    currenciesList: [],
    realTimeCurrentCurrencyPricePnl: {},
  };
};

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState: getInitialState(),
  reducers: {
    setCurrentCurrency: (state, action) => {
      state.currentCurrency = action.payload;
    },
    setCurrentCurrencyLeverage: (state, action) => {
      state.currentCurrencyLeverage = action.payload;
    },
    setCurrenciesList: (state, action) => {
      state.currenciesList = action.payload;
    },
    updateRealTimeCurrentCurrencyPricePnl: (state, action) => {
      state.realTimeCurrentCurrencyPricePnl = action.payload;
    },
  },
});

export const {
  setCurrentCurrency,
  setCurrentCurrencyLeverage,
  setCurrenciesList,
  updateRealTimeCurrentCurrencyPricePnl,
} = currenciesSlice.actions;

export default currenciesSlice.reducer;
