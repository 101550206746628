import store from '../plugins/redux';
import axios from 'axios';

export const createApi = () => {
	axios.defaults.headers = {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		'Access-Control-Allow-Credentials': true,
	};

	return axios.interceptors.request.use(
		function (config) {
			const token = store.getState().account.token;

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);

      return
    }
  );
};
