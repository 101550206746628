import { createSlice } from '@reduxjs/toolkit';

const getInitialState = () => {
	return {
		token: null,
		email: null,
		firstName: null,
		lastName: null,
		balance: 0,
		balanceHistory: [],
		infoBalance: {
			balance: 0,
			equity: 0,
			margin: 0,
			freeMargin: 0,
			marginLevel: 0,
			credit: 0,
		},
		accountStatus: null,
	};
};

export const accountSlice = createSlice({
	name: 'accounts',
	initialState: getInitialState(),
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setFirstName: (state, action) => {
			state.firstName = action.payload;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
		},
		setId: (state, action) => {
			state.id = action.payload;
		},
		setVerificationStatus: (state, action) => {
			state.VerificationStatus = action.payload;
		},
		setBalance: (state, action) => {
			state.balance = action.payload.balance;
		},
		setBalanceHistory: (state, action) => {
			state.balanceHistory = action.payload;
		},
		setInfoBalance: (state, action) => {
			state.infoBalance.balance = action.payload.balance;
			state.infoBalance.equity = action.payload.equity;
			state.infoBalance.margin = action.payload.margin;
			state.infoBalance.freeMargin = action.payload.freeMargin;
			state.infoBalance.marginLevel = action.payload.marginLevel;
			state.infoBalance.credit = action.payload.credit;
		},

		withdrawBalance: (state, action) => {
			state.balance = state.balance - action.payload;
		},
		setAccountStatus: (state, action) => {
			state.accountStatus = action.payload;
		},
		logout: state => {
			Object.assign(state, getInitialState());
		},
	},
});

export const {
	setToken,
	setEmail,
	setFirstName,
	setLastName,
	setBalance,
	setBalanceHistory,
	setInfoBalance,
	withdrawBalance,
	setId,
	setVerificationStatus,
	setAccountStatus,
	logout,
} = accountSlice.actions;

export default accountSlice.reducer;
