export default {
  succefull: {
    text: "Win",
    color: "#2eff68",
  },
  error: {
    text: "Lose",
    color: "#ff5c5c",
  },
  loading: {
    text: "loading",
    color: "white",
  },
};
