import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./plugins/redux";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import {createApi} from "./api/index";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_USE_SENTRY === 'true') {
    try {
        const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
        const SENTRY_TARGET_API = process.env.REACT_APP_SENTRY_TARGET_API;

        Sentry.init({
            dsn: SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [SENTRY_TARGET_API],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    } catch (e) {
        console.error("Failed to init Monitor");
    }
}

let persistor = persistStore(store);
const queryClient = new QueryClient();

createApi();

let App;

switch (process.env.REACT_APP_SERVER) {
    case "scg":
        App = React.lazy(() => import("./scg/App"));
        break;
    case "cfdx":
        App = React.lazy(() => import("./cfdx/App"));
        break;
    default:
        App = React.lazy(() => import("./scg/App"));
}

ReactDOM.render(
    <React.Fragment>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <PersistGate loading={null} persistor={persistor}>
                        <React.Suspense fallback={<div></div>}>
                            <App/>
                        </React.Suspense>
                    </PersistGate>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    </React.Fragment>,
    document.getElementById("root")
);